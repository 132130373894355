import React from "react"
import Container from "../components/structural/Container"
import SubLayout from "../components/Sublayout"
import { LocaleStoreContext } from "../components/LocaleStore"
import Button from "../components/Button"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import Collapse from "rc-collapse"
import "rc-collapse/assets/index.css"
import motion from "../util/motion"
import { useStaticQuery, graphql } from "gatsby"

const Card = styled.div`
  max-width: 900px;
  margin: auto;

  .rc-collapse {
    background-color: #fff;
    border-radius: 10px;

    border: none;
    width: 100%;
    > .rc-collapse-item {
      border: none;
    }
    margin-bottom: 32px;
  }

  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    color: #4a4a4a;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    width: calc(100% - 64px);
    background-color: white;
    border-radius: 10px;
    margin: auto;
    align-items: center;
    padding: 32px;
    /* text-align: center; */
  }

  .rc-collapse
    > .rc-collapse-item
    > .rc-collapse-content
    > .rc-collapse-content-box {
    p {
      font-size: 16px;
    }
    text-align: left;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
`

export default function Projects() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/project/*" } }
      ) {
        edges {
          node {
            fields {
              html_de
              html_en
            }
            frontmatter {
              name_de
              name_en
              sort
            }
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.edges
    .sort((a, b) => a.node.frontmatter.sort - b.node.frontmatter.sort)
    .map((e, i) => {
      return (
        <LocaleStoreContext.Consumer key={i}>
          {({ locale }) => {
            return (
              <Collapse accordion={true} openMotion={motion}>
                <Collapse.Panel
                  header={e.node.frontmatter["name_" + locale]}
                  collapsible="header"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: e.node.fields["html_" + locale],
                    }}
                  />
                  <ButtonContainer>
                    <a href="/donate">
                    <Button>
                      <FormattedMessage id="project.sponsor.btn" />
                    </Button>
                    </a>
                  </ButtonContainer>
                </Collapse.Panel>
              </Collapse>
            )
          }}
        </LocaleStoreContext.Consumer>
      )
    })
  return (
    <SubLayout>
      <Container heading="projects.heading">
        <Card>{projects}</Card>
      </Container>
    </SubLayout>
  )
}
